// crewSlice.ts
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { iCrew, iWorkOrder } from "../../types";
import { deleteCrew, fetchCrewList, postCrew } from "../thunk/crewApi";
import { setAssignedWorkOrders } from "./workorderSlice";
import { fetchWorkorderList } from "../thunk/workorderApi";
import { createMapFromWorkOrderList } from "../../utils/helper";

interface ICrewState {
  crewList: iCrew[];
  selectedCrewList: iCrew[];
  selectedCrew: iCrew | undefined;
  selectedCrewDate: string | null;
  crewListLoading: boolean;
  crewListError: string;
  crewPostLoading: boolean;
  crewPostData: string;
  crewPostError: string;
  crewDeleteLoading: boolean;
  crewDeleteData: string;
  crewDeleteError: string;
  existingCrewId: string | null;
  openCreateCrewModal: boolean;
}

export const InitalCrew: iCrew = {
  technician2EmployeeId: null,
  endDate: "99991231",
  technician3EmployeeName: null,
  technician4EmployeeId: null,
  crewName: "",
  technician4EmployeeName: null,
  technician3EmployeeId: null,
  updatedAt: "",
  startDate: "20000101",
  technician2EmployeeName: null,
  technician1EmployeeName: "",
  technician1EmployeeId: "",
  equipments: [], // Assum
};

const initialState: ICrewState = {
  crewList: [],
  selectedCrewList: [],
  selectedCrew: undefined,
  selectedCrewDate: null,
  crewListLoading: false,
  crewListError: "",
  crewPostLoading: false,
  crewPostData: "",
  crewPostError: "",
  crewDeleteLoading: false,
  crewDeleteData: "",
  crewDeleteError: "",
  existingCrewId: null,
  openCreateCrewModal: false,
};

const crewSlice = createSlice({
  name: "crew",
  initialState,
  reducers: {
    setCrewList: (state, action: PayloadAction<iCrew[]>) => {
      state.crewList = action.payload;
    },
    setSelectedCrew: (state, action: PayloadAction<iCrew | undefined>) => {
      state.selectedCrew = action.payload;
    },
    setSelectedCrewDate: (state, action: PayloadAction<string | null>) => {
      state.selectedCrewDate = action.payload;
    },
    setSelectedCrewList: (state, action: PayloadAction<iCrew[]>) => {
      state.selectedCrewList = action.payload;
    },
    setExistingCrewId: (state, action: PayloadAction<string | null>) => {
      state.existingCrewId = action.payload;
    },
    setOpenCreateCrewModal: (state, action: PayloadAction<boolean>) => {
      state.openCreateCrewModal = action.payload;
    },
    clearCrewPostData: (state) => {
      state.crewPostData = "";
      state.crewPostError = "";
    },
    clearCrewDeleteData: (state) => {
      state.crewDeleteData = "";
      state.crewDeleteError = "";
    },
  },
  extraReducers: (Builder) => {
    // //Read assigned work orders to update selected crew list
    // Helper function to get crew IDs from work orders
    const getCrewIdsFromWorkOrders = (
      workOrders: Record<string, iWorkOrder[]>
    ): string[] => {
      const crewIds = Object.values(workOrders)
        .flat()
        .map((workOrder) => workOrder.crewId)
        .filter(
          (crewId): crewId is string => crewId !== null && crewId !== undefined
        );

      return Array.from(new Set(crewIds));
    };

    // Helper function to update selected crew list without duplicates
    const updateSelectedCrewList = (state: ICrewState, crewIds: string[]) => {
      const selectedCrewListFromWorkOrders = state.crewList.filter(
        (crew: iCrew) => crew.crewId && crewIds.includes(crew.crewId)
      );

      // Use a map to remove duplicates based on crewId
      const uniqueCrewList = [
        ...state.selectedCrewList,
        ...selectedCrewListFromWorkOrders,
      ].filter(
        (crew, index, self) =>
          index === self.findIndex((c) => c.crewId === crew.crewId)
      );

      state.selectedCrewList = uniqueCrewList;
    };

    // Updated reducers
    Builder.addCase(setAssignedWorkOrders, (state, action) => {
      const crewIds = getCrewIdsFromWorkOrders(action.payload);
      updateSelectedCrewList(state, crewIds);
    });

    Builder.addCase(fetchWorkorderList.fulfilled, (state, action) => {
      const { assignedTasks } =
        action.payload.data && createMapFromWorkOrderList(action.payload.data);
      const crewIds = getCrewIdsFromWorkOrders(
        Object.fromEntries(assignedTasks)
      );
      updateSelectedCrewList(state, crewIds);
    });

    //List reducer
    Builder.addCase(fetchCrewList.pending, (state, action) => {
      return {
        ...state,
        crewListLoading: true,
      };
    });
    Builder.addCase(fetchCrewList.fulfilled, (state, action) => {
      const updatedSelectedCrewList = state.crewPostData
        ? [
            ...state.selectedCrewList,
            ...action.payload.filter(
              (crew) => crew.crewId === state.crewPostData
            ),
          ].filter(
            (crew, index, self) =>
              index === self.findLastIndex((c) => c.crewId === crew.crewId)
          )
        : state.selectedCrewList;
      return {
        ...state,
        crewListLoading: false,
        crewList: action.payload,
        selectedCrewList: updatedSelectedCrewList,
        crewPostData: "",
      };
    });
    Builder.addCase(fetchCrewList.rejected, (state, action) => {
      return {
        ...state,
        crewListLoading: false,
        crewListError: action.payload
          ? action.payload
          : "OOPS! Internal server error",
      };
    });

    //Post Reducers
    Builder.addCase(postCrew.pending, (state) => {
      return {
        ...state,
        crewPostLoading: true,
      };
    });
    Builder.addCase(postCrew.fulfilled, (state, action) => {
      return {
        ...state,
        crewPostLoading: false,
        crewPostData: action.payload.crewId,
      };
    });
    Builder.addCase(postCrew.rejected, (state, action) => {
      return {
        ...state,
        crewPostLoading: false,
        crewPostError: action.payload
          ? action.payload
          : "OOPS! Internal server error",
      };
    });

    //Post Reducers Data
    Builder.addCase(deleteCrew.pending, (state) => {
      return {
        ...state,
        crewDeleteLoading: true,
      };
    });
    Builder.addCase(deleteCrew.fulfilled, (state, action) => {
      return {
        ...state,
        crewDeleteLoading: false,
        crewDeleteData: action.payload.errorDescription ? "" : "Crew Deleted", // in case of crew delete success, errorDescription will be empty but in case of warning it will have some value
        crewDeleteError: action.payload.errorDescription,
      };
    });
    Builder.addCase(deleteCrew.rejected, (state, action) => {
      return {
        ...state,
        crewDeleteLoading: false,
        crewDeleteError: action.payload
          ? action.payload
          : "OOPS! Internal server error",
      };
    });
  },
});

export const {
  setCrewList,
  setSelectedCrewList,
  setSelectedCrewDate,
  setSelectedCrew,
  setExistingCrewId,
  setOpenCreateCrewModal,
  clearCrewDeleteData,
  clearCrewPostData,
} = crewSlice.actions;

export default crewSlice.reducer;
