import { createTheme } from "@mui/material";
const MuiTheme = createTheme({
  typography: {
    fontFamily: ["Avenir Next LT Pro", "sans-serif"].join(","),
    button: {
      textTransform: "none",
    },
    // li: {
    //   fontSize: "14px",
    // },
  },

  palette: {
    primary: {
      main: "#88a13b",
    },
  },
  colors: {
    white: "#ffffff",
    green: "#88a13b",
    lightGreen: "#ebf2d5",
  },
  components: {
    MuiDialog: {
      styleOverrides: {
        root: {
          "&.auto-width .MuiPaper-root": {
            width: "auto",
          },
        },
        paper: {
          maxWidth: 464,
          borderRadius: 6,
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          color: "#595959",
          backgroundColor: "#ffffff",
          padding: "12px 18px",
          border: "1px solid #d9d9d980",
          boxShadow: "0px 2px 12px rgb(119 119 119 / 54%)",
          borderRradius: "6.52px",
          margin: "2px !important",
        },
        arrow: {
          color: "#ffffff",
          "&:before": {
            border: "1px solid rgba(217, 217, 217, 0.5)",
          },
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          height: 22,
          borderRadius: 11,
          backgroundColor: "#ffffff",
          marginBottom: 7,
        },
        label: {
          padding: "2px 6px 1px 8px",
          fontSize: 12,
          lineHeight: "22px",
          letterSpacing: 0.41,
          color: "#595959",
          userSelect: "none",
        },
      },
    },
  },
});

export default MuiTheme;
